import {legacy_createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import session from './session'
import productsReducer from './products'
import cartItemsReducer from './cartItems'
import reviewsReducer from './reviews'

const rootReducer = combineReducers({
    session,
    products: productsReducer,
    cartItems: cartItemsReducer,
    reviews: reviewsReducer
})

let enhancer;

if (process.env.NODE_ENV === 'production') {
    enhancer = applyMiddleware(thunk);
  } else {
    const logger = require('redux-logger').default;
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(applyMiddleware(thunk, logger));
  }

  const configureStore = (preloadedState) => {
    return legacy_createStore(rootReducer, preloadedState, enhancer)
  }

  export default configureStore